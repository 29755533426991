import { Component, OnInit, Input } from '@angular/core';
import { CustomViewEditDataService } from '../../../../../service/data-service/customview-edit-data.service';
import { Attribute } from '../../../../../model/attribute';
import { CategoryAttribute } from '../../../../../model/category-attribute';
import { LocalizedString } from '../../../../../model/localized-string';
import { TemplateViewSelectionMapping } from '../../../../../model/custom-view/template-view-selection-mapping';
import { SelectOptionMapping } from '../../../../../model/custom-view/select-option-mapping';
import { Language } from '../../../../../model/language';
import { Pageable } from '../../../../../model/pageable';
import { MapTypeaheadService } from '../../../../../service/typeahead/map-typeahead.service';
import { Observable } from 'rxjs';
import { Slice } from '../../../../../model/pim-response/slice';
import * as _ from 'lodash';

@Component({
    selector: 'app-tab-selection-mapping',
    templateUrl: './custom-view-tab-selection-mapping.component.html'
  })
  export class CustomViewTabSelectionMappingComponent implements OnInit {

    @Input()
    public inputType = 'editor';

    public singleChoiceListAttributesWithCategories: CategoryAttribute[] = [];
    public singleChoiceListAttributesWithCategoriesSelected: CategoryAttribute = null;
    public singleChoiceListAttributes: Attribute[] = [];
    public categoryAttributeSelected: CategoryAttribute;
    public templateViewSelectionMapping: TemplateViewSelectionMapping = new TemplateViewSelectionMapping();
    public selectionOptionMappings: SelectOptionMapping[] = [];
    public selectionLocalizedOptionMappings: SelectOptionMapping[] = [];
    public englishLanguage: Language = new Language();
  
  
    constructor(
      private readonly customViewEditDataService: CustomViewEditDataService,
      private readonly mapTypeaheadService: MapTypeaheadService
    ) { }

    ngOnInit(): void {
      this.englishLanguage = this.getEnglishLanguage(this.customViewEditDataService.getTemplateView()?.name);
      this.singleChoiceListAttributesWithCategoriesSelected = null;
      this.selectionOptionMappings = this.customViewEditDataService.getTemplateView()?.selectionMappingSingleChoiceList;
      this.selectionLocalizedOptionMappings = this.customViewEditDataService.getTemplateView()?.selectionMappingSingleChoiceListLocalized;
      this.singleChoiceListAttributesWithCategories = this.customViewEditDataService.findCustomView()?.customViewCategoryAttributes
      .filter(categoryAttribute => {
        return !!categoryAttribute && (categoryAttribute.attribute.type == "ENUMERATION" || categoryAttribute.attribute.type == "LOCALIZED_ENUMERATION");
      });
      
    }

    public getSingleChoiceListAttributesWithCategories() {
      return this.singleChoiceListAttributesWithCategories;
    }

    public singleChoiceListsTypeahead = (searchTerm: string, pageable: Pageable) =>
      this.typeaheadSearch(searchTerm, pageable, this.singleChoiceListAttributesWithCategories);

    public singleChoiceListsTypeaheadFormatter = (input: any): string => this.typeaheadFormatter(input);

    public typeaheadFormatter(model: CategoryAttribute) {
      return this.getLocalizedStringEnglishValue(model.attribute.name);
    }

    public typeaheadSearch(searchTerm: string, pageable: Pageable, totalList: CategoryAttribute[]): Observable<Slice<CategoryAttribute>> {
      return this.mapTypeaheadService.createSearchedInSlice(searchTerm, pageable, this.createTotalListMap(totalList));
    }

    private createTotalListMap(totalList: CategoryAttribute[]): Map<string, CategoryAttribute> {
      const totalListMap: Map<string, CategoryAttribute> = new Map();
  
      totalList.forEach(categoryAttributeList => totalListMap.set(
        _.toLower(this.getLocalizedStringEnglishValue(categoryAttributeList.attribute.name))
            + this.mapTypeaheadService.getDelimiter()
            + categoryAttributeList.id, categoryAttributeList))
      return totalListMap;
    }
  
    public getLocalizedStringEnglishValue(localizedStrings: LocalizedString[]): string {
      return localizedStrings.find((value: LocalizedString) => value.language.isoCode == 'en')?.value;
    }

    public getMappedOption(optionId: string) {
      let categoryAttributeId = this.singleChoiceListAttributesWithCategoriesSelected.id;
      let optionFound =  this.selectionOptionMappings.find(search => 
        (search.optionId === optionId && search.categoryAttributeId === categoryAttributeId));
      if(!optionFound) {
        optionFound = this.createEmptySelectOptionMapping(optionId, this.singleChoiceListAttributesWithCategoriesSelected.id);
        this.selectionOptionMappings.push(optionFound);
      } 
      
      return optionFound.mappings;
    }

    public getMappedLocalizedOption(optionId: string) {
      let categoryAttributeId = this.singleChoiceListAttributesWithCategoriesSelected.id;
      let optionFound =  this.selectionLocalizedOptionMappings.find(search => 
        (search.optionId === optionId && search.categoryAttributeId === categoryAttributeId));
      if(!optionFound) {
        optionFound = this.createEmptySelectOptionMapping(optionId, this.singleChoiceListAttributesWithCategoriesSelected.id);
        this.selectionLocalizedOptionMappings.push(optionFound);
      } 

      return optionFound.mappings;
    }

    public getSelectedSingleChoiceList() {
      return this.singleChoiceListAttributesWithCategoriesSelected.attribute.singleChoiceListValues.sort((a, b) => (a.order < b.order) ? -1 : 1);
    }

    public getSelectedSingleChoiceListLocalized() {
      return this.singleChoiceListAttributesWithCategoriesSelected.attribute.localizedEnumerationValues.sort((a, b) => (a.order < b.order) ? -1 : 1);
    }

    private getEnglishLanguage(localizedStrings: LocalizedString[]): Language{
      return localizedStrings.find((value: LocalizedString) => value.language.isoCode == 'en')?.language;
    }

    private createEmptySelectOptionMapping(optionId: string, categoryAttributeId: string):  SelectOptionMapping{
      const localizedString = new LocalizedString();
      localizedString.id = null;
      localizedString.language = this.englishLanguage;
      localizedString.value = '';
      let optionFound = new SelectOptionMapping(categoryAttributeId, optionId);
      optionFound.mappings.push(localizedString);
      return optionFound;
    }
    
  }