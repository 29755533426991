<div class="container-fluid pb-3">

  <h2 class="display-6 fw-normal" *ngIf="category.id; else addHeadline">{{ 'headline.administration.maintainCategory' | translate }}</h2>

  <ng-template #addHeadline>
    <h2 class="display-6 fw-normal">{{ 'headline.administration.addCategory' | translate }}</h2>
  </ng-template>

  <div class="row">
    <div class="col-8">

      <div class="row">
        <div class="col-6">

          <localized-string-edit
              id="administration-ls-category-name"
              [localizedStrings]="category.name"
              [label]="'label.administration.category.name' | translate"
              [englishRequired]="true">
          </localized-string-edit>

        </div>
        <div class="col-6">

          <localized-string-edit
              id="administration-ls-category-shortname"
              [localizedStrings]="category.shortName"
              [label]="'label.administration.category.short_name' | translate">
          </localized-string-edit>

        </div>
      </div>

      <div class="row upper_space">
        <div class="col-6">

          <div class="row">
            <div class="col-12 form-group">

              <label for="administration-textarea-category-description">{{'label.administration.category.description' | translate}}</label>
              <textarea type="text" class="form-control" id="administration-textarea-category-description" name="description" [(ngModel)]="category.description"></textarea>

            </div>
            <div class="col-12 form-group">


              <label for="administration-select-category-type">{{'label.administration.category.type' | translate}}</label>
              <select class="form-select" id="administration-select-category-type" required name="type" [(ngModel)]="category.type" (ngModelChange)="onCatagoryTypeChange()">
                <option *ngFor="let type of categoryType | enumToArray" value="{{type}}">{{ 'label.type.' + type | lowercase | camelCase | translate }}</option>
              </select>

            </div>
            <div *ngIf="!isCatalog()" class="col-12 form-group">

              <typeahead
                  id="administration-typeahead-category-parent"
                  [inputLabel]="'label.administration.category.parent' | translate"
                  [inputName]="'parent'"
                  [inputDisabled]="disableParent()"
                  [(model)]="category.parent"
                  [typeaheadFunction]="categoryParentTypeahead"
                  [resultTemplate]="categoryResultTemplate"
                  [inputFormatter]="categoryTypeaheadFormatter">
              </typeahead>

            </div>
            <div class="col-12 form-group">

              <div class="form-check form-switch">
                <input type="checkbox" class="form-check-input" id="readonly" [(ngModel)]="category.readonly">
                <label class="form-check-label" for="readonly">{{'label.administration.category.readonly' | translate}}</label>
              </div>

            </div>
            <div class="col-12 form-group">

              <div class="form-check form-switch">
                <input id="administration-category-checkbox-pim1migrated" type="checkbox" class="form-check-input" [(ngModel)]="category.pimOneMigrated">
                <label class="form-check-label" for="administration-category-checkbox-pim1migrated">{{'label.administration.category.pim1migrated'|translate}}</label>
              </div>

            </div>
            <div *ngIf="category.pimOneMigrated==true" class="col-12 form-group">

              <input
                  type="text"
                  class="form-control"
                  required
                  [(ngModel)]="category.pimOneIdentifier"
                  name="pimOneIdentifier"
                  id="administration-category-pim1Identifier"
                  placeholder="{{'label.administration.category.pim1identifier' | translate}}">

            </div>
          </div>
        </div>

        <div class="col-6">

          <div class="row">
            <div *ngIf="!isCatalog()" class="col-12 form-group">

              <multiple-value-selector
                  id="administration-mvs-category-regionalvalidity"
                  [(values)]="category.regionalValidity"
                  [inputLabel]="'label.administration.category.regional_validity' | translate"
                  [inputName]="'regional-validity'"
                  [typeahead]="regionalValidityTypeahead"
                  [resultFormatter]="categoryTypeaheadFormatter"
                  [valueTemplate]="categoryValueTemplate">
              </multiple-value-selector>

            </div>
            <div *ngIf="!isCatalog()" class="col-12 form-group">

              <multiple-value-selector
                  id="administration-mvs-category-catalogvalidity"
                  [(values)]="category.catalogValidity"
                  [inputLabel]="'label.administration.category.catalog_validity' | translate"
                  [inputName]="'catalog-validity'"
                  [typeahead]="catalogValidityTypeahead"
                  [resultFormatter]="categoryTypeaheadFormatter"
                  [valueTemplate]="categoryValueTemplate">
              </multiple-value-selector>

            </div>
          </div>
        </div>
      </div>

      <div class="row upper_space">

        <div class="col-12">

          <localized-string-list-edit
              id="administration-ls-category-synonyms"
              [label]="'label.synonyms' | translate"
              [localizedStrings]="category.synonyms"
              inputType="textarea" >
          </localized-string-list-edit>

        </div>

      </div>

    </div>

    <div class="col-4 form-group">

      <multiple-value-selector
        id="administration-typeahead-category-attribute"
        [(values)]="category.categoryAttributes"
        [inputLabel]="'label.administration.category.attributes' | translate"
        [inputName]="'attribute'"
        [typeahead]="attributeTypeahead"
        [resultFormatter]="attributeTypeaheadFormatter"
        [resultTemplate]="attributeResultTemplate"
        [valueTemplate]="attributeValueTemplate"
        [identifierField]="'attribute'"
        [executeBeforeAdd]="transformToCategoryAttribute">
      </multiple-value-selector>

    </div>

  </div>

  <div class="row upper_space" *ngIf="category && category.id">
    <div class="col-12">
      <div >
        <button class="btn btn-secondary" [disabled]="!categoryInUse" (click)="showUsages=!showUsages">
          <span *ngIf="categoryInUse === undefined">{{ 'button.administration.category.usage.loading' | translate}}
            <div class="spinner-border spinner-border-sm ms-lg-2" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
          <span *ngIf="categoryInUse === false">{{ 'button.administration.category.usage.nousagetoshow' | translate}}</span>
          <span *ngIf="categoryInUse === true && !showUsages">{{ 'button.administration.category.usage.show' | translate}}</span>
          <span *ngIf="categoryInUse === true && !!showUsages">{{ 'button.administration.category.usage.hide' | translate}}</span>
        </button>
      </div>
      <div>
        <category-usage-administration *ngIf="showUsages" [category]="category"></category-usage-administration>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12 align-right">
      <icon-button buttonType="cancel" (buttonClick)="cancel()"></icon-button>
      <icon-button buttonType="delete" (buttonClick)="delete()" [buttonDisabled]="categoryInUse || !!categoryOrig?.categoryAttributes?.length || !category.id"></icon-button>
      <icon-button *ngIf="category && category.id" buttonType="save" (buttonClick)="save()"></icon-button>
      <icon-button *ngIf="category && !category.id" buttonType="add" (buttonClick)="save()"></icon-button>
    </div>
  </div>

</div>

<ng-template #categoryValueTemplate let-result="result">
  <typeahead-template-category-relatives [model]="result"></typeahead-template-category-relatives>
</ng-template>

<ng-template #categoryResultTemplate let-result="result" let-term="term">
  <typeahead-template-category-relatives [model]="result" [term]="term?.trim().split(' ')"></typeahead-template-category-relatives>
</ng-template>

<ng-template #attributeResultTemplate let-r="result" let-t="term">
  <div #popupElement> </div>
  <div>
    <ngb-highlight [result]="resultFormatter(r)" [term]="t?.trim().split(' ')"></ngb-highlight>
    <i class="lighter-font">&nbsp;[{{ typeFormatter(r) }}]</i>
  </div>
</ng-template>

<ng-template #attributeValueTemplate let-r="result" let-t="term">
  <div class="category-attribute">
    <span class="attribute-name" [class.reduced]="r.attribute?.unit || r.attribute?.type?.length > 22" ngbTooltip="{{ r.attribute?.name | localizedStringsFilter }}">
      <a [routerLink]="getAdminLink(r)">{{ r.attribute?.name | localizedStringsFilter }}</a>
    </span>
    <span style><a [routerLink]="getAdminLink(r)"><fa-icon icon="external-link-alt" class="fa-sg"></fa-icon></a></span>
    <span class="attribute-type">{{ r.attribute?.type }}</span>
    <span class="attribute-unit" *ngIf="r.attribute?.unit"><fa-icon icon="scale-balanced" ngbTooltip="{{ 'label.unit' | translate}}"></fa-icon> {{
      !!r.attribute?.unit?.symbol ? r.attribute?.unit?.symbol : (r.attribute?.unit?.name | localizedStringsFilter) }}</span>
  </div>
</ng-template>
