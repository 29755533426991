import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, Observable, finalize, of } from 'rxjs';
import { Material } from '../model/material';
import { Page } from '../model/pim-response/page';
import { UrlParameter } from '../model/parameter/url-parameter';
import { SortType } from '../model/sort-type';
import { UrlParameterService } from './url-parameter.service';
import { HttpFileService } from './http-file.service';
import { NotificationService } from './notification.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchDownloadService {

  public processingDownloadSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private MAX_DOWNLOAD_LIMIT: number = 100000;

  constructor(
    private readonly translateService: TranslateService,
    private readonly httpClient: HttpClient,
    private readonly httpFileService: HttpFileService,
    private readonly urlParameterService: UrlParameterService,
    private readonly notificationService: NotificationService) { }

  public downloadExcelResult(url: string, lastQueryParameterString: string, lastTotalElements: number): void {
    if (lastQueryParameterString === null) {
      return;
    }
    if (lastTotalElements > this.MAX_DOWNLOAD_LIMIT) {
      this.notificationService.addErrorNotification(
        this.translateService.instant('search_result.download.limit_error', { total_records: lastTotalElements, max_records: this.MAX_DOWNLOAD_LIMIT})
      );
      return;
    }
    if (lastTotalElements == 0) {
      this.notificationService.addErrorNotification('search_result.download.no_records_error');
      return;
    }

    this.processingDownloadSearch.next(true);
    const estimatedTime =  Math.round(90 / 33_000 * lastTotalElements);
    const downloadingNotification = this.notificationService.addSuccessNotification(
      this.translateService.instant('search_result.download.downloading', { estimated_time: estimatedTime})
      );
    this.httpFileService.downloadWithoutLoadingIndicator(url).pipe(
        finalize(() => {
          this.processingDownloadSearch.next(false);
        })
      )
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          this.httpFileService.prepareBlobDownload(response);
        },
        error: () => {
          this.notificationService.removeNotification(downloadingNotification);
          this.notificationService.addErrorNotification('search_result.download.error');
        },
        complete: () => {
          this.notificationService.removeNotification(downloadingNotification);
          this.notificationService.addSuccessNotification('search_result.download.success');
        }
      })
  }
}
