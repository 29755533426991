export class MaterialStatus {
  static readonly CONSISTENT = new MaterialStatus("CONSISTENT", "", "consistent/initial");
  static readonly BLOCKED_01 = new MaterialStatus("BLOCKED_01", "01", "Blocked for procmnt/whse");
  static readonly BLOCKED_02 = new MaterialStatus("BLOCKED_02", "02", "Blocked for task list/BOM");
  static readonly Y2 = new MaterialStatus("Y2", "Y2", "Sellout inconsistent");
  static readonly Y3 = new MaterialStatus("Y3", "Y3", "Sellout no wait - incons.");
  static readonly Y4 = new MaterialStatus("Y4", "Y4", "Sellout inconsistent");
  static readonly Y6 = new MaterialStatus("Y6", "Y6", "Deleted inconsistent");
  static readonly YC = new MaterialStatus("YC", "YC", "consistent/distr. locked");
  static readonly YD = new MaterialStatus("YD", "YD", "");
  static readonly YF = new MaterialStatus("YF", "YF", "not for external sourcing");
  static readonly YK = new MaterialStatus("YK", "YK", "Lite: Deleted incons.");
  static readonly YL = new MaterialStatus("YL", "YL", "Lite: initial incons.");
  static readonly YN = new MaterialStatus("YN", "YN", "inkonsistent/no distribut");
  static readonly YO = new MaterialStatus("YO", "YO", "Lite: Sellout incons.");
  static readonly YT = new MaterialStatus("YT", "YT", "Blocked for procurm incon");
  static readonly YW = new MaterialStatus("YW", "YW", "L:Sellout w/o wait. p inc");
  static readonly YX = new MaterialStatus("YX", "YX", "Destroy mat, inc.");
  static readonly Z2 = new MaterialStatus("Z2", "Z2", "sellout planned");
  static readonly Z3 = new MaterialStatus("Z3", "Z3", "Sellout no wait");
  static readonly Z4 = new MaterialStatus("Z4", "Z4", "Sellout");
  static readonly Z6 = new MaterialStatus("Z6", "Z6", "Deleted");
  static readonly ZC = new MaterialStatus("ZC", "ZC", "Locked for procurement");
  static readonly ZD = new MaterialStatus("ZD", "ZD", "Locked for disposal");
  static readonly ZF = new MaterialStatus("ZF", "ZF", "not for external sourcing");
  static readonly ZK = new MaterialStatus("ZK", "ZK", "Lite: Deleted");
  static readonly ZL = new MaterialStatus("ZL", "ZL", "Lite: initial");
  static readonly ZO = new MaterialStatus("ZO", "ZO", "Lite: Sellout");
  static readonly ZT = new MaterialStatus("ZT", "ZT", "Blocked for procurement");
  static readonly ZW = new MaterialStatus("ZW", "ZW", "Lite: Sellout w/o wait. p");
  static readonly ZX = new MaterialStatus("ZX", "ZX", "Destroy material");
  static readonly ZS = new MaterialStatus("ZS", "ZS", "");

  private name: string;
  private type: string;
  private text: string;

  constructor(name: string, type: string = "", text: string = "") {
    this.name = name;
    this.type = type;
    this.text = text;
  }

  public getText(): string {
    return this.text;
  }

  public getType(): string {
    return this.type;
  }

  public getName(): string {
    return this.name;
  }

  public valueOf(name: string): MaterialStatus {
    return MaterialStatus[name]
  }
}
