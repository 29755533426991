import { LocalizedString } from '../localized-string';
import { Realm } from '../realm/realm';
import { CategoryAttribute } from '../category-attribute';
import { User } from '../user';
import { CustomViewType } from './custom-view-type';
import { CustomView } from './custom-view';
import { CategoryAttributeAttributeValue } from '../category-attribute-attribute-value';
import { TemplateViewList } from './template-view-list/template-view-list';

export class TemplateView implements CustomView {
  public id: string = null;
  public name: LocalizedString[] = [];
  public realm: Realm = null;
  public description: string = null;
  public customViewCategoryAttributes: CategoryAttribute[] = [];
  public users: User[] = [];
  public templateViewCategoryAttributesDocumentVersionSpecific: CategoryAttribute[] = [];
  public templateViewCategoryAttributesTemplateViewSpecific: CategoryAttribute[] = [];
  public templateViewLists: TemplateViewList[] = [];
  public templateFileNames: LocalizedString[] = [];
  public categoryAttributeAttributeValues: CategoryAttributeAttributeValue[] = [];
  public type: CustomViewType = CustomViewType.TEMPLATE_VIEW;
}
