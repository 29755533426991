import { LocalizedString } from './localized-string';
import { MaterialType } from './material-type';
import { Recommendation } from './recommendation';
import { Realm } from './realm/realm';
import { Unit } from './unit';
import { MultipleValueSelectorElement } from './multiple-value-selector/multiple-value-selector-element';
import { MaterialStatus } from './material-status';
import { AlphaCode } from './alpha-code';

export class Material implements MultipleValueSelectorElement {
    id: string;
    name: LocalizedString[] = [];
    marketingName: LocalizedString[] = [];
    parent: Material;
    type: MaterialType;
    synonyms: LocalizedString[] = [];
    recommendation: Recommendation[] = [];
    realm: Realm;
    europeanArticleNumber: string;
    supplier: string;
    status: MaterialStatus;
    substanceAccessGroup: any;
    begru: string;
    od: string;
    materialGroup: string;
    totalShelfLife: string;
    minimumRemainingShelfLife: string;
    baseUnitOfMeasure: Unit;
    size: number;
    articleType: string;
    isReadOnly: boolean = false;
    goo: string;
    productType: string;
    sdsVisibility: string;
    categoriesAssigned: boolean;
    documentsAssigned: boolean;
    packagingType: string;
    alphaCode: string;
}
