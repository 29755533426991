import { Component, Input } from '@angular/core';

@Component({
  selector: 'typeahead-template-category-relatives',
  templateUrl: './category-relatives.html',
  styleUrls: ['./category-relatives.less']
})
export class TypeaheadCategoryRelativesTemplate {

  @Input()
  public model: any;

  @Input()
  public term: string = null;

  @Input()
  public showCategoryLink = true;

  public createParentList(object: any, nodeList: any[] = []): any[] {
    if(!object){
      return nodeList;
    }

    if (object.parent) {
      nodeList = this.createParentList(object.parent, nodeList);
    }
    nodeList.push(object);
    return nodeList;
  }
}
