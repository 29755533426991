import { Component, Input, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { finalize } from "rxjs/operators";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpFileService } from '../../../../../service/http-file.service';
import { BackendValidationService } from '../../../../../service/form-validation/backend-validation.service';
import { environment } from '../../../../../../environments/environment';
import { MassMaintenanceModalConfig } from "../../../../../model/config/mass-maintenance-modal.config";
import { NotificationService } from '../../../../../service/notification.service';

@Component({
  selector: '',
  templateUrl: './generic-mass-maintenance.component.html',
  styleUrls: ['./generic-mass-maintenance.component.less']
})
export class GenericMassMaintenanceModal implements OnInit {

  public selectedExportFile: File = null;
  public selectedImportFiles: Map<string, File> = new Map<string, File>();

  public processingDownloadTemplate: boolean = false;
  public processingExportFile: boolean = false;
  public processingImportFile: boolean = false;

  @Input()
  public config: MassMaintenanceModalConfig;

  @Input()
  public visibleModalSections: number = 3;

  public stepSize: number;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly httpFileService: HttpFileService,
    private readonly backendValidationService: BackendValidationService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.stepSize = Math.floor(100 / this.visibleModalSections);
  }

  public keepOriginalOrder = (a, b) => a.key;

  public downloadTemplate(): void {
    if (this.config.templateDownloadDisabled) {
      alert("Template download disabled");
    } else {
      this.processingDownloadTemplate = true;
      this.httpFileService.downloadWithoutLoadingIndicator(
        environment.restUrl + this.config.templateDownloadUrlPath
      )
        .pipe(
          finalize(() => {
            this.processingDownloadTemplate = false;
          })
        )
        .subscribe({
          next: (response: HttpResponse<Blob>) => {
            this.httpFileService.prepareBlobDownload(response);
          },
          error: () => {
            this.notificationService.addErrorNotification('massmaintenance.modal.generic.download.template_error');
          },
          complete: () => {
            this.notificationService.addSuccessNotification('massmaintenance.modal.generic.download.template_success');
          }
        })
    }
  }

  public selectExportFile($event: any): void {
    let file: File = $event.target.files[0];
    this.selectedExportFile = file;
  }

  public selectImportFile($event: any, fileKey: string = "file"): void {



    let file: File = $event.target.files[0];
    this.selectedImportFiles.set(fileKey, file);
  }

  public allImportFilesSelected() {

    let allRequiredFilesSelected= true;

    this.config.importMultipleUploadFiles.forEach((value, key, map) => {
      if(value.mandatory && (!this.selectedImportFiles.has(value.field) || !this.selectedImportFiles.get(value.field))) {
          allRequiredFilesSelected = false;
      }
    })

    return allRequiredFilesSelected;
  }

  public startImport(): void {
    if (this.config.importDisabled) {
      alert("Import disabled");
      return;
    }

    this.processingImportFile = true;
    this.httpFileService.uploadAndDownloadMultipleFilesWithoutLoadingIndicator(
      this.selectedImportFiles,
      environment.restUrl + this.config.importUrlPath
    )
      .pipe(
        this.backendValidationService.renderErrorMessages(),
        finalize(() => {
          this.processingImportFile = false;
        }))
      .subscribe((response: HttpResponse<Blob>) => {
        this.httpFileService.prepareBlobDownload(response);
        }
      );
  }

  public startExport() {
    if (this.config.exportDisabled) {
      alert("Export disabled");
      return;
    }

    this.processingExportFile = true;
    this.httpFileService.uploadAndDownloadWithoutLoadingIndicator(
      this.selectedExportFile,
      environment.restUrl + this.config.exportUrlPath
    )
      .pipe(
        this.backendValidationService.renderErrorMessages(),
        finalize(() => {
          this.processingExportFile = false;
        }))
      .subscribe((response: HttpResponse<Blob>) => {
          this.httpFileService.prepareBlobDownload(response);
        }
      )
  }
}
