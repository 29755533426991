export enum MaterialType {
  ART = 'ART',
  PRD = 'PRD',
  PBG = 'PBG',
  ZKIT = 'ZKIT',
  ZPAR = 'ZPAR',
  VERP = 'VERP',
  THIRD_PARTY_PRODUCT = 'THIRD_PARTY_PRODUCT',
  BASE_INGREDIENT = 'BASE_INGREDIENT'
}
