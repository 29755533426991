<div class="row paddig-top" >
  <div class="col-4">
    <div>
      <h3>{{ 'label.type.' + categoryTypes.PRODUCT_GROUP | lowercase | camelCase | translate }}</h3>
      <app-table [page]="productGroups" (rowClick)="navigateToCategory($event)" (pageableHandler)="switchProductGroupPage($event)">
        <app-table-column name="{{'label.administration.category.usage.id'|translate}}" field="id">
        </app-table-column>
        <app-table-column name="{{'label.administration.category.usage.name'|translate}}" field="name"
          [valueFunction]="getLocalizedStringsValue" displayLinkIcon="true"></app-table-column>
      </app-table>
    </div>
    <div>
      <h3>{{ 'label.type.' + categoryTypes.PROCESS | lowercase | camelCase | translate }}</h3>
      <app-table [page]="processes" (rowClick)="navigateToCategory($event)" (pageableHandler)="switchProcessPage($event)">
        <app-table-column name="{{'label.administration.category.usage.id'|translate}}" field="id">
        </app-table-column>
        <app-table-column name="{{'label.administration.category.usage.name'|translate}}" field="name"
          [valueFunction]="getLocalizedStringsValue" displayLinkIcon="true"></app-table-column>
      </app-table>
    </div>
    <div>
      <h3>{{ 'label.type.' + categoryTypes.SERVICE | lowercase | camelCase | translate }}</h3>
      <app-table [page]="services" (rowClick)="navigateToCategory($event)" (pageableHandler)="switchServicePage($event)">
        <app-table-column name="{{'label.administration.category.usage.id'|translate}}" field="id">
        </app-table-column>
        <app-table-column name="{{'label.administration.category.usage.name'|translate}}" field="name"
          [valueFunction]="getLocalizedStringsValue" displayLinkIcon="true"></app-table-column>
      </app-table>
    </div>
  </div>
  <div class="col-4">
    <div>
      <h3>{{ 'label.type.' + categoryTypes.INDUSTRY | lowercase | camelCase | translate }}</h3>
      <app-table [page]="industries" (rowClick)="navigateToCategory($event)" (pageableHandler)="switchIndustryPage($event)">
        <app-table-column name="{{'label.administration.category.usage.id'|translate}}" field="id">
        </app-table-column>
        <app-table-column name="{{'label.administration.category.usage.name'|translate}}" field="name"
          [valueFunction]="getLocalizedStringsValue" displayLinkIcon="true"></app-table-column>
      </app-table>
    </div>
    <div>
      <h3>{{ 'label.type.' + categoryTypes.TECHNICAL_CATEGORY | lowercase | camelCase | translate }}</h3>
      <app-table [page]="technicalCategories" (rowClick)="navigateToCategory($event)" (pageableHandler)="switchTechnicalCategoryPage($event)">
        <app-table-column name="{{'label.administration.category.usage.id'|translate}}" field="id">
        </app-table-column>
        <app-table-column name="{{'label.administration.category.usage.name'|translate}}" field="name"
          [valueFunction]="getLocalizedStringsValue" displayLinkIcon="true"></app-table-column>
      </app-table>
    </div>
    <div>
      <h3>{{ 'label.type.' + categoryTypes.DOCUMENT_CREATION_CATEGORY | lowercase | camelCase | translate }}</h3>
      <app-table [page]="docCreationCategories" (rowClick)="navigateToCategory($event)" (pageableHandler)="switchDocCreationPage($event)">
        <app-table-column name="{{'label.administration.category.usage.id'|translate}}" field="id">
        </app-table-column>
        <app-table-column name="{{'label.administration.category.usage.name'|translate}}" field="name"
                          [valueFunction]="getLocalizedStringsValue" displayLinkIcon="true"></app-table-column>
      </app-table>
    </div>
  </div>
  <div class="col-4">
    <div>
      <h3>{{ 'label.type.' + categoryTypes.APPLICATION | lowercase | camelCase | translate }}</h3>
      <app-table [page]="applications" (rowClick)="navigateToCategory($event)" (pageableHandler)="switchApplicationPage($event)">
        <app-table-column name="{{'label.administration.category.usage.id'|translate}}" field="id">
        </app-table-column>
        <app-table-column name="{{'label.administration.category.usage.name'|translate}}" field="name"
          [valueFunction]="getLocalizedStringsValue" displayLinkIcon="true"></app-table-column>
      </app-table>
    </div>
    <div>
      <h3>{{ 'label.type.' + categoryTypes.SOLUTION | lowercase | camelCase | translate }}</h3>
      <app-table [page]="solutions" (rowClick)="navigateToCategory($event)" (pageableHandler)="switchSolutionPage($event)">
        <app-table-column name="{{'label.administration.category.usage.id'|translate}}" field="id">
        </app-table-column>
        <app-table-column name="{{'label.administration.category.usage.name'|translate}}" field="name"
          [valueFunction]="getLocalizedStringsValue" displayLinkIcon="true"></app-table-column>
      </app-table>
    </div>
  </div>
</div>
