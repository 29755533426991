import { Component, Input, OnInit } from '@angular/core';
import { Attribute } from './../../../../model/attribute';
import { Category } from './../../../../model/category';
import { Page } from './../../../../model/pim-response/page';
import { Pageable } from './../../../../model/pageable';
import { Router } from '@angular/router';
import { AttributeUsageService } from './../../../../service/attribute-usage.service';
import { LocalizedStringService } from './../../../../service/localized-string.service';
import { LocalizedString } from './../../../../model/localized-string';
import { CategoryType } from './../../../../model/category-type';

@Component({
  selector: 'attribute-usage-administration',
  templateUrl: './attribute-usage-administration.component.html',
  styleUrls: ['./attribute-usage-administration.component.less']
})
export class AttributeUsageAdministrationComponent implements OnInit {
  @Input() public attribute: Attribute;

  public categoryTypes = CategoryType;

  public productGroups: Page<Category> = new Page();
  public industries: Page<Category> = new Page();
  public applications: Page<Category> = new Page();
  public processes: Page<Category> = new Page();
  public technicalCategories: Page<Category> = new Page();
  public docCreationCategories: Page<Category> = new Page();
  public solutions: Page<Category> = new Page();
  public services: Page<Category> = new Page();

  public getLocalizedStringsValue: (value: LocalizedString[]) => string = (value: LocalizedString[]) => this.localizedStringService.getLocalizedStringsValue(value);

  constructor(
    private router: Router,
    private attributeUsageService: AttributeUsageService,
    private localizedStringService: LocalizedStringService
  ) { }

  ngOnInit() {
    this.switchProductGroupPage(new Pageable());
    this.switchIndustryPage(new Pageable());
    this.switchApplicationPage(new Pageable());
    this.switchProcessPage(new Pageable());
    this.switchTechnicalCategoryPage(new Pageable());
    this.switchDocCreationPage(new Pageable());
    this.switchServicePage(new Pageable());
    this.switchSolutionPage(new Pageable());
  }

  public switchProductGroupPage(pageable: Pageable) {
    this.attributeUsageService.findCategoriesWithAssignedAttributeByType(this.categoryTypes.PRODUCT_GROUP, this.attribute, pageable)
      .subscribe((categories: Page<Category>) => this.productGroups = categories);
  }

  public switchIndustryPage(pageable: Pageable) {
    this.attributeUsageService.findCategoriesWithAssignedAttributeByType(this.categoryTypes.INDUSTRY, this.attribute, pageable)
      .subscribe((categories: Page<Category>) => this.industries = categories);
  }

  public switchApplicationPage(pageable: Pageable) {
    this.attributeUsageService.findCategoriesWithAssignedAttributeByType(this.categoryTypes.APPLICATION, this.attribute, pageable)
      .subscribe((categories: Page<Category>) => this.applications = categories);
  }

  public switchProcessPage(pageable: Pageable) {
    this.attributeUsageService.findCategoriesWithAssignedAttributeByType(this.categoryTypes.PROCESS, this.attribute, pageable)
      .subscribe((categories: Page<Category>) => this.processes = categories);
  }

  public switchTechnicalCategoryPage(pageable: Pageable) {
    this.attributeUsageService.findCategoriesWithAssignedAttributeByType(this.categoryTypes.TECHNICAL_CATEGORY, this.attribute, pageable)
      .subscribe((categories: Page<Category>) => this.technicalCategories = categories);
  }

  public switchDocCreationPage(pageable: Pageable) {
    this.attributeUsageService.findCategoriesWithAssignedAttributeByType(this.categoryTypes.DOCUMENT_CREATION_CATEGORY, this.attribute, pageable)
      .subscribe((categories: Page<Category>) => this.docCreationCategories = categories);
  }

  public switchSolutionPage(pageable: Pageable) {
    this.attributeUsageService.findCategoriesWithAssignedAttributeByType(this.categoryTypes.SOLUTION, this.attribute, pageable)
      .subscribe((categories: Page<Category>) => this.solutions = categories);
  }

  public switchServicePage(pageable: Pageable) {
    this.attributeUsageService.findCategoriesWithAssignedAttributeByType(this.categoryTypes.SERVICE, this.attribute, pageable)
      .subscribe((categories: Page<Category>) => this.services = categories);
  }

  public navigateToCategory(category: Category) {
    this.router.navigateByUrl('adminstration/category/' + category.id);
  }
}
