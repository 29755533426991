import { Component } from '@angular/core';
import { Material } from '../../../../model/material';
import { MaterialEditDataService } from '../../../../service/data-service/material-edit-data.service';
import { Observable } from 'rxjs';
import { BusinessHierarchyNode } from '../../../../model/business-hierarchy/business-hierarchy-node';

@Component({
  selector: 'material-tab-spass-view',
  templateUrl: './material-tab-spass-view.component.html',
  styleUrls: ['./material-tab-spass-view.component.less']
})
export class MaterialTabSpassViewComponent {

  constructor(private readonly materialEditDataService: MaterialEditDataService) {}

  public getMaterial = (): Material => this.materialEditDataService.findMaterial();

  public getBusinessHierarchy = (): Observable<BusinessHierarchyNode> => this.materialEditDataService.getBusinessHierarchy();

}
