import { Component, OnInit } from '@angular/core';
import { Pageable } from '../../../../model/pageable';
import { Category } from '../../../../model/category';
import { CategoryMaterial } from '../../../../model/category-material';
import { CategoryType } from '../../../../model/category-type';
import { CategoryMaterialService } from '../../../../service/category-material.service';
import { CategoryService } from '../../../../service/category.service';
import { CategoryOnLeafLevelTypeaheadService } from '../../../../service/typeahead/category-on-leaf-level-typeahead.service';
import { CategoryByTypeTypeaheadService } from '../../../../service/typeahead/category-by-type-typeahead.service';
import { MaterialEditDataService } from '../../../../service/data-service/material-edit-data.service';
import InjectIsReadonlyUser from '../../../../decorator/inject-is-readonly-user.decorator';
import InjectIsAdmin from '../../../../decorator/inject-is-admin.decorator';
import InjectIsAdvanced from '../../../../decorator/inject-is-advanced.decorator';
import { Observable } from 'rxjs';

@Component({
  selector: 'material-tab-categorization',
  templateUrl: './material-tab-categorization.component.html',
  styleUrls: ['./material-tab-categorization.component.less']
})
export class MaterialTabCategorizationComponent implements OnInit {

  @InjectIsReadonlyUser
  public isReadOnlyUser: Observable<boolean>;

  @InjectIsAdmin
  public isAdmin: Observable<boolean>;

  @InjectIsAdvanced
  public isAdvanced: Observable<boolean>;

  private leafCategories = new Map();

  constructor(
    private readonly categoryMaterialService: CategoryMaterialService,
    private readonly categoryOnLeafLevelTypeaheadService: CategoryOnLeafLevelTypeaheadService,
    private readonly categoryByTypeTypeaheadService: CategoryByTypeTypeaheadService,
    private readonly categoryService: CategoryService,
    private readonly materialEditDataService: MaterialEditDataService
  ) {}

  ngOnInit(): void {
    this.materialEditDataService.categoryMaterialsBehaviourSubject.asObservable().subscribe(() => this.initiateCategoryLeafMap())
  }

  public categoryTypeaheadIsA = (searchTerm: string, pageable: Pageable) => this.categoryOnLeafLevelTypeaheadService.typeaheadSearch(searchTerm, pageable,
    [CategoryType.PRODUCT_GROUP], this.findProductGroups().map(cm => cm.category.id));

  public categoryTypeaheadCanBeUsedFor = (searchTerm: string, pageable: Pageable) => this.categoryOnLeafLevelTypeaheadService.typeaheadSearch(searchTerm, pageable,
    [CategoryType.APPLICATION], this.findApplications().map(cm => cm.category.id));

  public categoryTypeaheadRelevantForIndustries = (searchTerm: string, pageable: Pageable) => this.categoryOnLeafLevelTypeaheadService.typeaheadSearch(searchTerm, pageable,
    [CategoryType.INDUSTRY], this.findSelectedIndustries().map(cm => cm.category.id));

  public categoryTypeaheadMarketedTo = (searchTerm: string, pageable: Pageable) => this.categoryByTypeTypeaheadService.typeaheadSearch(searchTerm, pageable,
    [CategoryType.REGION], this.findRegions().map(cm => cm.category.id));

  public categoryTypeaheadCanSupportProcesses = (searchTerm: string, pageable: Pageable) => this.categoryOnLeafLevelTypeaheadService.typeaheadSearch(searchTerm, pageable,
    [CategoryType.PROCESS], this.findProcesses().map(cm => cm.category.id));

  public categoryTypeaheadCanOfferServices = (searchTerm: string, pageable: Pageable) => this.categoryOnLeafLevelTypeaheadService.typeaheadSearch(searchTerm, pageable,
    [CategoryType.SERVICE], this.findServices().map(cm => cm.category.id));

  public categoryTypeaheadAreCreatedUsingAttOf = (searchTerm: string, pageable: Pageable) => this.categoryByTypeTypeaheadService.typeaheadSearch(searchTerm, pageable,
    [CategoryType.DOCUMENT_CREATION_CATEGORY], this.findDocumentCreations().map(cm => cm.category.id));

  public findProductGroups(): CategoryMaterial[] {
    return this.findLeafCategoryMaterials(CategoryType.PRODUCT_GROUP);
  }

  public findApplications(): CategoryMaterial[] {
    return this.findLeafCategoryMaterials(CategoryType.APPLICATION);
  }

  public findIndustries(): CategoryMaterial[] {
    return this.findIndustryCategoryMaterials();
  }

  public findRegions(): CategoryMaterial[] {
    return this.findCategoryMaterialsByType(CategoryType.REGION);
  }

  public findProcesses(): CategoryMaterial[] {
    return this.findLeafCategoryMaterials(CategoryType.PROCESS);
  }

  public findServices(): CategoryMaterial[] {
    return this.findLeafCategoryMaterials(CategoryType.SERVICE);
  }

  public findDocumentCreations(): CategoryMaterial[] {
    return this.findCategoryMaterialsByType(CategoryType.DOCUMENT_CREATION_CATEGORY);
  }

  public findSelectedIndustries(): CategoryMaterial[] {
    return this.materialEditDataService.findCategoryMaterials().filter((categoryMaterial: CategoryMaterial) => {
        return categoryMaterial.category.type === CategoryType.INDUSTRY && categoryMaterial.manuallyMaintained
      }
    );
  }

  private findCategoryMaterialsByType(categoryType: CategoryType): CategoryMaterial[] {
    return this.materialEditDataService.findCategoryMaterials().filter((categoryMaterial: CategoryMaterial) =>
        categoryMaterial.category.type === categoryType && categoryMaterial.manuallyMaintained
    );
  }

  private findLeafCategoryMaterials(categoryType: CategoryType): CategoryMaterial[] {
    return this.materialEditDataService.findCategoryMaterials().filter((categoryMaterial: CategoryMaterial) => {
      if(!categoryMaterial.category?.type) {
      }
        return categoryMaterial.category.type === categoryType && this.leafCategories.get(categoryMaterial.category.id)
      }
    );
  }

  private findIndustryCategoryMaterials(): CategoryMaterial[] {

    const categoryMaterialsToShow = new Set<CategoryMaterial>();
    const leafCategoryMaterials = this.findLeafCategoryMaterials(CategoryType.INDUSTRY);

    leafCategoryMaterials.forEach((cm: CategoryMaterial) => categoryMaterialsToShow.add(cm));

    const nonIndustryLeafcategoryMaterials: CategoryMaterial[] = this.materialEditDataService.findCategoryMaterials().filter((cm: CategoryMaterial) =>
        cm.category.type != CategoryType.INDUSTRY && this.leafCategories.get(cm.category.id));

    nonIndustryLeafcategoryMaterials.forEach( cm => {
      const category = this.firstAncestorOfType(CategoryType.INDUSTRY, cm.category);
      if(!!category) {
        this.materialEditDataService.findCategoryMaterials().filter(
          (cm: CategoryMaterial) => cm.category.id == category.id
        ).forEach(
          (cm: CategoryMaterial) => {
            cm.isReadOnly = cm.manuallyMaintained ? false : true;
            categoryMaterialsToShow.add(cm);
          }
        );
      }
    });

    return Array.from(categoryMaterialsToShow);
  }

  public createParentList(category: Category, nodeList: Category[] = []): Category[] {
    return this.categoryService.createParentList(category, nodeList);
  }

  private firstAncestorOfType(categoryType: CategoryType, category: Category): Category {

    if(!category.parent) {
      return null;
    }

    if(category.parent.type == categoryType) {
      return category.parent
    }

    return this.firstAncestorOfType(categoryType, category.parent)
  }

  public addValue = (category: Category) => {
    this.categoryService.load(category.id).subscribe((fullCategory: Category) => {
      this.materialEditDataService.createAndAddCategoryMaterialsWithAttributeValues(
        this.materialEditDataService.findMaterial(), fullCategory, this.materialEditDataService.findCategoryMaterials(), true);
      this.materialEditDataService.updateCategoryMaterials(this.materialEditDataService.catMats);
      this.materialEditDataService.reloadCustomViewCategoryAttributes();
    });
  }

  public deleteValue = (categoryMaterial: CategoryMaterial) => {
    this.categoryMaterialService.removeCategoryMaterials(categoryMaterial.category, this.materialEditDataService.findCategoryMaterials());
    this.materialEditDataService.updateCategoryMaterials(this.materialEditDataService.catMats);
    this.materialEditDataService.reloadCustomViewCategoryAttributes();
  }

  private initiateCategoryLeafMap() {
    this.leafCategories.clear();
    this.materialEditDataService.findCategoryMaterials().forEach(categoryMaterial => {
      this.leafCategories.set(categoryMaterial.category.id, true);
    });
    this.materialEditDataService.findCategoryMaterials().forEach(categoryMaterial => {
      if (!!categoryMaterial.category.parent) {
        this.leafCategories.set(categoryMaterial.category.parent.id, false);
      }
    });
  }

  public findMaterial = () => this.materialEditDataService.findMaterial();
  public findCategoryMaterials = () => this.materialEditDataService.findCategoryMaterials();
}
