export enum CategoryType {
    PRODUCT_GROUP = 'PRODUCT_GROUP',
    INDUSTRY = 'INDUSTRY',
    APPLICATION = 'APPLICATION',
    PROCESS = 'PROCESS',
    TECHNICAL_CATEGORY = 'TECHNICAL_CATEGORY',
    DOCUMENT_TYPE = 'DOCUMENT_TYPE',
    SECURITY_CLASSIFICATION = 'SECURITY_CLASSIFICATION',
    OWNER = 'OWNER',
    REGION = 'REGION',
    LEGAL_AREA = "LEGAL_AREA",
    SOLUTION = "SOLUTION",
    SERVICE = "SERVICE",
    CATALOG = "CATALOG",
    VISIBILITY = "VISIBILITY",
    DOCUMENT_CHANGE_REASON = "DOCUMENT_CHANGE_REASON",
    DOCUMENT_CREATION_CATEGORY = "DOCUMENT_CREATION_CATEGORY"
}
